import './App.css';
import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import '@aws-amplify/ui-react/styles.css';
import { Amplify, Storage } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import Navbar from './components/navbar';
import Rechner from './components/rechner';
import Auskunft from './components/auskunft';
import Impressum from './components/impressum';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

const AppWrapper = () => {

  // hide the username field
  const formFields = {
    signIn: {
      username: {
        labelHidden: true,
        isRequired: true,
        placeholder: "if you see this field, reload the page"
      },
    },
  }

  useEffect(() => {
    // hack to hide the username field and make it invisible
    setTimeout(() => {
      const input = document.querySelector('input[name="username"]');
      if (input) {
        input.setAttribute("value", "labor");
        input.setAttribute("type", "hidden");
      }
    });
  })

  return (
    <Authenticator hideSignUp={true} formFields={formFields} >
      {({ signOut, user }) => (
        <App user={user} signOut={signOut} />
      )}
    </Authenticator>
  );
}

const App = ({ user, signOut }) => {
  const [products, setProducts] = useState([])
  const [profiles, setProfiles] = useState([])

  useEffect(() => {
    fetchProducts();
    fetchProfiles();
  }, [])

  // fetch product.json from S3
  async function fetchProducts() {
    try {
      const productsData = await Storage.get(`products.json`, { download: true });
      // data.Body is a Blob
      productsData.Body.text().then(productsJSON => {
        const products = JSON.parse(productsJSON);
        //console.log(products);
        setProducts(products.products);
      });
    } catch (err) { console.log('error fetching products:', err) }
  }

  // fetch profiles.json from S3
  async function fetchProfiles() {
    try {
      const profileData = await Storage.get(`profiles.json`, { download: true });
      // data.Body is a Blob
      profileData.Body.text().then(profilesJSON => {
        const profiles = JSON.parse(profilesJSON);
        //console.log(profiles);
        setProfiles(profiles.profiles);
      });
    } catch (err) { console.log('error fetching profiles:', err) }
  }

  return (
    <div className='App'>
      <Navbar signOut={signOut} />
      <Routes>
        <Route path="*" element={<Rechner products={products} />} />
        <Route path="/rechner" element={<Rechner products={products} />} />
        <Route path="/auskunft" element={<Auskunft profiles={profiles} />} />
        <Route path="/impressum" element={<Impressum />} />
      </Routes>

        


    </div>
  );
}

export default AppWrapper;
