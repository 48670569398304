import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";


const Rechner = ({ products }) => {
  const [selection, setSelection] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //console.log("loading products data");
    setLoading(false);
  }, [])

  const productcolumns = [
    { field: 'id', headerName: 'ID' },
    { field: 'number', headerName: 'Ziffer', width: 130 },
    { field: 'title', headerName: 'Name', width: 250 },
    { field: 'price', headerName: 'Preis 1.0', type: 'number', width: 130 },
    { field: 'price115', headerName: 'Preis 1.15', type: 'number', width: 130 },
    { field: 'fee', headerName: 'Gebühr', type: 'number', width: 130 },
    { field: 'type', headerName: 'Typ', width: 80 },
  ];

  const selectioncolumns = [
    { field: 'element', headerName: 'Elemente', width: 200 },
    { field: 'price10', headerName: 'Preis 1.0', type: 'number', width: 120 },
    { field: 'margin10', headerName: 'Gewinn 1.0', type: 'number', width: 120 },
    { field: 'price115', headerName: 'Preis 1.15', type: 'number', width: 120 },
    { field: 'margin115', headerName: 'Preis 1.15', type: 'number', width: 120 },
    { field: 'fee', headerName: 'Gebühr', type: 'number', width: 120 },
    { field: 'pointsH1', headerName: 'GöÄ Punkte', type: 'number', width: 100 },
  ];

  function changeSelection(params) {
    //console.log(params);
    let selectedProduct = [];
    let newPrice10 = 0;
    let newPrice10H1 = 0;
    let newPrice115 = 0;
    let newPrice115H1 = 0;
    let newFee = 0;
    let newpointsH1 = 0;
    let newMargin10 = 0;
    let newMargin115 = 0;

    params.map(selectedId => {
      selectedProduct = products.find(x => x.id === selectedId);
      //console.log("product:",selectedProduct);
      if (selectedProduct.type === "H1") {
        newPrice10H1 = +(newPrice10H1 + selectedProduct.price).toFixed(2);
        newPrice115H1 = +(newPrice115H1 + selectedProduct.price115).toFixed(2);
        newpointsH1 = newpointsH1 + selectedProduct.GoA;
      } else {
        newPrice10 = +(newPrice10 + selectedProduct.price).toFixed(2);
        newPrice115 = +(newPrice115 + selectedProduct.price115).toFixed(2);
      }
      newFee = +(newFee + selectedProduct.fee).toFixed(2);
      return [];
    })
    if (newpointsH1 > 480) {
      console.log("H1 Punkte über 480!")
      newPrice10H1 = 27.98;
      newPrice115H1 = 32.18;
    }

    newPrice10 = +(newPrice10 + newPrice10H1).toFixed(2);
    newMargin10 = +(newPrice10 - newFee).toFixed(2);
    newPrice115 = +(newPrice115 + newPrice115H1).toFixed(2);
    newMargin115 = +(newPrice115 - newFee).toFixed(2);

    let currentSelection = {
      "id": "S1",
      "element": "Gewählt: " + params.length,
      "price10": newPrice10,
      "margin10": newMargin10,
      "price115": newPrice115,
      "margin115": newMargin115,
      "fee": newFee,
      "pointsH1": newpointsH1,
    };

    //    console.log(selection);
    // get a deep copy so we can modify it safely
    let newSelection = [...selection];
    if (newSelection.length === 1) {
      // the summary table has already been filled before, so just replace the first entry, 
      // which is the first row of the summary table
      newSelection[0] = currentSelection;
      // and store it to state
      setSelection(newSelection);
    } else if (selection.length > 1) {
      // the summary table has already been filled before,
      // AND a profile has been stored, so we replace the current selection
      // AND we need to re-calculate the difference
      let diff = calculateDifference(currentSelection, newSelection[1]);
      newSelection[0] = currentSelection;
      newSelection[2] = diff;
      setSelection(newSelection);
    } else {
      // nothing happened so far, so let's just store the current selection to state
      setSelection([currentSelection]);
    }

  }

  function storeProfile() {
    //console.log ("storing profile");
    // if nothing has been stored yet, just exit
    if (selection.length === 0) return;
    // deep copy of the current selection first entry and put it into the "selection" array as a 2nd row
    // so it will be displayed in the upper table of the page
    let newSelection = [...selection];
    // clone the current selection
    let newProfile = { ...newSelection[0] };
    // let's assign a unique id, so the table can be rendered correctly
    newProfile.id = "S2";
    newProfile.element = "Gespeichertes Profil";

    let diff = calculateDifference(selection[0], newProfile);
    // now push the profile to the selection store - either as a new element or replacing the old one
    if (selection.length > 1) {
      // there has been a profile stored before
      newSelection[1] = newProfile;
      newSelection[2] = diff;
      // and store it to state
    } else {
      // no profile has been stored before
      newSelection.push(newProfile);
      newSelection.push(diff);
    }
    //console.log(newSelection);
    setSelection(newSelection);
  }

  function clearProfile() {
    //console.log ("clearing profile");
    setSelection([{ ...selection[0] }]);
  }

  function calculateDifference(profile1, profile2) {
    // now calculate the difference
    let difference = {
      "id": "S3",
      "element": "Differenz",
      "price10": profile1.price10 - profile2.price10,
      "margin10": profile1.margin10 - profile2.margin10,
      "price115": profile1.price115 - profile2.price115,
      "margin115": profile1.margin115 - profile2.margin115,
      "fee": profile1.fee - profile2.fee,
      "pointsH1": profile1.pointsH1 - profile2.pointsH1,
    };
    return difference;
  }

  return (
    <div style={{ height: 1000, width: 1100, justifyContent: 'center', padding: 20 }}>
      <div style={{ height: 267, width: 980 }}>
        <DataGrid
          rows={selection}
          columns={selectioncolumns}
        />
      </div>
      <p></p>
      <Stack spacing={2} direction="row">
        <Button variant="contained" style={{ backgroundColor: "#ff6c0e" }} onClick={storeProfile}>Profil speichern</Button>
        <Button variant="contained" style={{ backgroundColor: "#ff6c0e" }} onClick={clearProfile}>Profil löschen</Button>
        <Typography variant="p" component="div" sx={{ pl: 6 }}>
          Angaben ohne Gewähr
        </Typography>
      </Stack>
      <p></p>
      <div style={{ height: 800, width: 980 }}>
        <DataGrid
          rows={products}
          columns={productcolumns}
          checkboxSelection
          loading={loading}
          columnVisibilityModel={{
            id: false,
            type: false,
          }}
          onSelectionModelChange={(params) => {
            changeSelection(params);
          }}
        />
      </div>
      <Link to="/impressum" style={{ textDecoration: "none" }}>Impressum</Link>
    </div>

  );
}

export default Rechner;

/*
export default class MyCalculator extends React.Component{
  constructor(props) {
    super(props);
    this.state = ({
      price10: 0,
      price115: 0,
      fee: 0,
      pointsH1: 0,
      data: this.props.data,
      selected: [],
      page: this.props.type,
      margin10: 0,
      margin115: 0,
      storedprice10: 0,
      storedprice115: 0,
      storedfee: 0,
      storedpointsH1: 0,
      storedmargin10: 0,
      storedmargin115: 0,
      storedSelection: []
    });
    this.onRowSelect = this.onRowSelect.bind(this);
    this.calculateValues = this.calculateValues.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.findRowInData = this.findRowInData.bind(this);
    this.afterColumnFilter = this.afterColumnFilter.bind(this);
    this.storeProfile = this.storeProfile.bind(this);
    this.clearProfile = this.clearProfile.bind(this);
  }

  findRowInData(id) {
    for (var i = 0; i < this.state.data.length; i++) {
        if (this.state.data[i].id === id)
            return this.state.data[i]; // Return as soon as the object is found
    }
    return null; // The object was not found
  }

  storeProfile() {
    //console.log(this.state.selected);
    var clonedArray = JSON.parse(JSON.stringify(this.state.selected));

    this.setState({
      storedprice10: this.state.price10,
      storedprice115: this.state.price115,
      storedfee: this.state.fee,
      storedpointsH1: this.state.pointsH1,
      storedmargin10: this.state.margin10,
      storedmargin115: this.state.margin115,
      storedSelection: clonedArray
    });

    //console.log(this.state);
  }

  clearProfile() {
    this.setState({
      storedprice10: 0,
      storedprice115: 0,
      storedfee: 0,
      storedpointsH1: 0,
      storedmargin10: 0,
      storedmargin115: 0,
      storedSelection: []
    });

    //console.log(this.state);
  }

  calculateValues(theSelected) {
    var newPrice10 = 0;
    var newPrice10H1 = 0;
    var newPrice115 = 0;
    var newPrice115H1 = 0;
    var newFee = 0;
    var newpointsH1 = 0;
    var newMargin10 = 0;
    var newMargin115 = 0;

    for (var i = 0; i < theSelected.length; i++) {
      var thisRow = this.findRowInData(theSelected[i]);
      if (thisRow != null) {
        if (thisRow.type == "H1") {
          newPrice10H1 = +(newPrice10H1 + thisRow.price).toFixed(2);
          newPrice115H1 = +(newPrice115H1 + thisRow.price115).toFixed(2);
          newpointsH1 = newpointsH1 + thisRow.GoA;
        } else {
          newPrice10 = +(newPrice10 + thisRow.price).toFixed(2);
          newPrice115 = +(newPrice115 + thisRow.price115).toFixed(2);
        }
        newFee = +(newFee + thisRow.fee).toFixed(2);
        thisRow = null;
      } else {
        console.log ('Error: Row not found in data. Something must have gone terribly wrong.')
      }
    }
    if (newpointsH1 > 480 ) {
      newPrice10H1 = 27.98;
      newPrice115H1 = 32.18;
    }

    newPrice10 = +(newPrice10 + newPrice10H1).toFixed(2);
    newMargin10 = +(newPrice10 - newFee).toFixed(2);
    newPrice115 = +(newPrice115 + newPrice115H1).toFixed(2);
    newMargin115 = +(newPrice115 - newFee).toFixed(2);

    this.setState({
      price10: newPrice10,
      price115: newPrice115,
      fee: newFee,
      pointsH1: newpointsH1,
      selected: theSelected,
      margin10: newMargin10,
      margin115: newMargin115
    });
  }

  onRowSelect(row, isSelected){
    //console.log(row);
    //console.log("selected: " + isSelected);
    var oldArray = this.state.selected;
    if (isSelected)  {
      var newArray = oldArray.concat([row.id]);
      this.calculateValues(newArray);
    } else {
      var index = oldArray.indexOf(row.id);
      if (index > -1) {
        oldArray.splice(index, 1);
        this.calculateValues(oldArray);
      }
    }
  }

  onSelectAll(isSelected) {
    var emptyArray = [];
    if (isSelected) {
      for (var i = 0; i < this.state.data.length; i++) {
        emptyArray.push(this.state.data[i].id);
      }
      this.calculateValues(emptyArray);
    } else {
      this.setState({
        price10: 0,
        price115: 0,
        fee: 0,
        pointsH1: 0,
        selected: [],
        margin10: 0,
        margin115: 0
      });
    }
  }

  afterColumnFilter(filterConds, results) {
    if (results && results.length === 1) {
      this.refs.table.handleSelectRow(results[0], true);
    }
  }

  numberFormatter(cell, row) {
    return cell.toFixed(2);
  }

  handlerClickCleanFiltered() {
    if (this.state.page === "auskunft") {
      this.refs.short.cleanFiltered();
      this.refs.number.cleanFiltered();
      this.refs.title.cleanFiltered();
      this.refs.type.cleanFiltered();
    } else {
      this.refs.number.cleanFiltered();
      this.refs.title.cleanFiltered();
    }

  }

  render() {
    var selectRowProp = {
      mode: "checkbox",  //checkbox for multi select, radio for single select.
      clickToSelect: true,   //click row will trigger a selection on that row.
      bgColor: "#9BCD9B",   //selected row background color
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      showOnlySelected: true
    };

    let options = {
      "clearSearch": true,
      "hideSizePerPage": true, //you can change the dropdown list for size per page
      "sizePerPage": 50,  //which size per page you want to locate as default
      "afterColumnFilter": this.afterColumnFilter
    }

    if (this.state.page === "auskunft") {
      return (
        <div>
          <div className="react-bs-table-tool-bar">
            <Table responsive bordered condensed>
              <thead>
                <tr>
                  <th className="col-sm-1"><b>Elemente</b></th>
                  <th><b>Preis 1,0</b></th>
                  <th><b>Preis 1,15</b></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Ausgewählt:  {this.state.selected.length}</td>
                  <td className="cell10">{this.state.price10.toFixed(2)}</td>
                  <td className="cell115">{this.state.price115.toFixed(2)}</td>
                </tr>
                <tr>
                  <td colSpan="3"><b>(*) = Fremdversand</b></td>
                </tr>
              </tbody>
            </Table>
            <Button bsStyle="primary" bsSize="small" onClick={this.handlerClickCleanFiltered.bind(this)}>Clear Filter</Button>
          </div>
          <BootstrapTable data={this.state.data} striped={true} hover={true} selectRow={selectRowProp}
             search={false} pagination={true} options={options} ref='table'>
            <TableHeaderColumn dataField="id" isKey={true} dataAlign="center" hidden={true}>ID</TableHeaderColumn>

            <TableHeaderColumn dataField="short" dataSort={true} dataAlign="center"
              filter={{type: "TextFilter", placeholder: "Kürzel"}} ref='short'>Kürzel</TableHeaderColumn>

            <TableHeaderColumn dataField="number" dataAlign="center" dataSort={true}
              filter={{type: "TextFilter", placeholder: "Ziffer"}} ref='number' >Ziffer</TableHeaderColumn>

            <TableHeaderColumn dataField="title" dataSort={true} dataAlign="left"
              filter={{type: "TextFilter", placeholder: "Name"}} width="500" ref='title'>Name</TableHeaderColumn>

            <TableHeaderColumn dataField="price" dataSort={true} dataAlign="center" dataFormat={this.numberFormatter}>Preis 1,0</TableHeaderColumn>

            <TableHeaderColumn dataField="price115" dataSort={true} dataAlign="center" dataFormat={this.numberFormatter}>Preis 1,15</TableHeaderColumn>

            <TableHeaderColumn dataField="type" dataSort={true} dataAlign="center"
              filter={{type: "TextFilter", placeholder: "H1/H2/H3/H4"}} ref='type'>Typ</TableHeaderColumn>
          </BootstrapTable>
        </div>
      );
    } else {

      let panelInstance = (
        <Panel collapsible defaultExpanded={false} header="Profilverwaltung" className="mypanel">
          <ButtonToolbar fill>
            <Button bsSize="small" onClick={this.storeProfile}>Profil speichern</Button>
            <Button bsSize="small" onClick={this.clearProfile}>Profil löschen</Button>
          </ButtonToolbar>
        </Panel>
      );

      let chartData10 = {
          labels: ["Preis 1,0", "Gewinn 1,0", "Gebühr"],
          datasets: [
            {
              fillColor: [
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)'
              ],
              data: [
                this.state.price10,
                this.state.margin10,
                this.state.fee
              ]
            }
          ]
      };

      let chartData115 = {
          labels: [
            'Preis 1,15',
            'Gewinn 1,15',
            'Gebühr'
          ],
          datasets: [
            {
              fillColor: [
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)'
              ],
              data: [
                this.state.price115,
                this.state.margin115,
                this.state.fee
              ]
            }
          ]
      };
 //
      return (
        <div>
          <Bar width="300" height="200" data={chartData10} />
          <Bar width="300" height="200" data={chartData115} />
          <div className="col-md-6">
            <Table responsive bordered condensed>
              <thead>
                <tr>
                  <th className="col-sm-1"><b>Elemente</b></th>
                  <th><b>Preis 1,0</b></th>
                  <th><b>Gewinn 1,0</b></th>
                  <th><b>Preis 1,15</b></th>
                  <th><b>Gewinn 1,15</b></th>
                  <th className="col-sm-1"><b>Gebühr</b></th>
                  <th className="col-sm-2"><b>GoÄ Punkte (H1)</b></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Ausgewählt: {this.state.selected.length}</td>
                  <td className="cell10"><b>{this.state.price10.toFixed(2)}</b></td>
                  <td className="cell10"><b>{this.state.margin10.toFixed(2)}</b></td>
                  <td className="cell115"><b>{this.state.price115.toFixed(2)}</b></td>
                  <td className="cell115"><b>{this.state.margin115.toFixed(2)}</b></td>
                  <td><b>{this.state.fee.toFixed(2)}</b></td>
                  <td><b>{this.state.pointsH1}</b></td>
                </tr>
                <tr>
                  <td>Gespeichertes Profil</td>
                  <td>{this.state.storedprice10.toFixed(2)}</td>
                  <td>{this.state.storedmargin10.toFixed(2)}</td>
                  <td>{this.state.storedprice115.toFixed(2)}</td>
                  <td>{this.state.storedmargin115.toFixed(2)}</td>
                  <td>{this.state.storedfee.toFixed(2)}</td>
                  <td>{this.state.storedpointsH1}</td>
                </tr>
              </tbody>
            </Table>
            <Button bsSize="small" onClick={this.storeProfile}>Profil speichern</Button>
            <Button bsSize="small" onClick={this.clearProfile}>Profil löschen</Button>
          </div>
          <div className="react-bs-table-tool-bar">
            <Button bsStyle="primary" bsSize="small" onClick={this.handlerClickCleanFiltered.bind(this)}>Filter löschen</Button>
          </div>
          <div>
            <BootstrapTable data={this.state.data} striped={true} hover={true} selectRow={selectRowProp}
               search={false} options={options} ref='table'>
              <TableHeaderColumn dataField="id" isKey={true} dataAlign="center" hidden={true}>ID</TableHeaderColumn>

              <TableHeaderColumn dataField="number" dataAlign="center" dataSort={true}
                filter={{type: "TextFilter", placeholder: "Ziffer"}} ref='number'>Ziffer</TableHeaderColumn>

              <TableHeaderColumn dataField="title" dataSort={true} dataAlign="center"
                filter={{type: "TextFilter", placeholder: "Name"}} ref='title'>Name</TableHeaderColumn>

              <TableHeaderColumn dataField="GoA" dataSort={true} dataAlign="center">GoÄ Punkte</TableHeaderColumn>

              <TableHeaderColumn dataField="price" dataSort={true} dataAlign="center">Preis 1,0</TableHeaderColumn>

              <TableHeaderColumn dataField="price115" dataSort={true} dataAlign="center">Preis 1,15</TableHeaderColumn>

              <TableHeaderColumn dataField="fee" dataSort={true} dataAlign="center" dataFormat={this.numberFormatter}>Gebühr</TableHeaderColumn>

              <TableHeaderColumn dataField="type" dataSort={true} dataAlign="center" hidden={true}>Typ</TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      );
    }
  }
}
*/