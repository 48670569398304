import logo from '../logo.svg';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

const Navbar = ({ signOut }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ bgcolor: "black" }}>
        <Toolbar>
          <Link to="https://www.labor-becker.de/">
            <Box
              component="img"
              sx={{
                height: 64,
              }}
              alt="Labor Becker"
              src={logo}
            />
          </Link>
          <Typography variant="h6" component="div" sx={{ pl: 4 }}>
            <Link to="/rechner" style={{ textDecoration: "none", color: "white" }}>Rechner</Link>
          </Typography>

          <Typography variant="h6" component="div" sx={{ pl: 4 }}>
            <Link to="/auskunft" style={{ textDecoration: "none", color: "white" }}>Gebührenauskunft</Link>
          </Typography>

          <Button color="inherit" sx={{ marginLeft: "auto" }} onClick={signOut}>Logout</Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;